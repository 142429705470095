import i18n from '@/i18n/index'
import constant from '@/utils/constant.js'

export default {
  formData: [{
    title: '',
    name: 0,
    createDateItem: [{
      span: 18,
      prop: 'online',
      value: true,
      name: 'switch',
      label: i18n.t('form.product.online'),
      Object: {}
    }, {
      span: 18,
      prop: 'rest_id',
      name: 'select',
      options: [],
      label: i18n.t('form.rest.restList'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        key: 'id',
        value: 'id',
        rules: [
          { required: true, message: i18n.t('text.require', { name: i18n.t('form.rest.restList') }) }
        ]
      }
    }, {
      span: 8,
      prop: 'name',
      name: 'input',
      label: i18n.t('form.common.cnName'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON,
        rules: [
          { required: true, message: i18n.t('text.require', { name: i18n.t('form.common.cnName') }) }
        ]
      }
    }, {
      span: 8,
      prop: 'name_en',
      name: 'input',
      label: i18n.t('form.common.enName'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 8,
      prop: 'rank',
      name: 'input',
      type: 'number',
      label: i18n.t('form.product.ranking'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 24,
      prop: 'images_attributes',
      name: 'image',
      label: i18n.t('form.common.image'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        delete: '_destroy',
        limit: 99
        // imageName: 'remote_image_url'
      }
    }, {
      span: 11,
      prop: 'price',
      name: 'input',
      type: 'number',
      label: i18n.t('form.rest.defaultPrice'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON,
        rules: [
          { required: true, message: i18n.t('text.require', { name: i18n.t('form.rest.defaultPrice') }) },
          { pattern: new RegExp(/^[1-9]\d*$/, 'g'), message: i18n.t('message.positiveInter', { name: i18n.t('form.rest.defaultPrice') }), trigger: 'blur' }
        ]
      }
    }, {
      span: 11,
      prop: 'max_customer',
      name: 'input',
      type: 'number',
      label: i18n.t('form.rest.maxSize'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 18,
      prop: 'intro',
      name: 'input',
      type: 'textarea',
      label: i18n.t('form.product.shortDescriptionCn'),
      Object: {}
    }, {
      span: 18,
      prop: 'intro_en',
      name: 'input',
      type: 'textarea',
      label: i18n.t('form.product.shortDescriptionEn'),
      Object: {}
    }]
  }, {
    title: i18n.t('form.rest.restPolicy'),
    name: 1,
    createDateItem: [{
      span: 18,
      prop: 'rest_policies_attributes',
      name: 'array',
      value: [],
      label: i18n.t('form.rest.restPolicy'),
      Object: {
        delete: '_destroy',
        'array-label-width': '120px'
      },
      createDateFirstItem: [{
        span: 8,
        prop: 'name',
        name: 'input',
        label: i18n.t('form.common.cnName'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
          maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
        }
      }, {
        span: 8,
        prop: 'name_en',
        name: 'input',
        label: i18n.t('form.common.enName'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
          maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
        }
      }, {
        span: 8,
        prop: 'rank',
        name: 'input',
        type: 'number',
        label: i18n.t('form.product.ranking'),
        placeholder: i18n.t('placeholder.showRanking'),
        Object: {
          rules: [
            { required: false, message: i18n.t('text.require', { name: i18n.t('form.product.ranking') }) },
            { pattern: new RegExp(/^[1-9]\d*$/, 'g'), message: i18n.t('message.positiveInter', { name: i18n.t('form.product.ranking') }), trigger: 'blur' }
          ]
        }
      }, {
        span: 24,
        prop: 'desc',
        name: 'input',
        type: 'textarea',
        label: i18n.t('form.common.descriptionCn'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
          maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
        }
      }, {
        span: 24,
        prop: 'desc_en',
        name: 'input',
        type: 'textarea',
        label: i18n.t('form.common.descriptionEn'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
          maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
        }
      }]
    }]
  }]
}
