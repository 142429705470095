// 组件
import alterForm from '@/components/alterForm'
// 接口
import {
  createRestType,
  updateRestType,
  previewRestType,
  getRestList
} from '@/services/rests'
// 其他
import loading from '@/utils/loading.json'
import jsonData from './jsonData'
import constant from '@/utils/constant.js'
import utils from '@/utils/Utils'
import _ from 'lodash'

export default {
  name: 'restTypeUodate',
  components: {
    alterForm
  },
  data () {
    return {
      modifyData: {},
      collapseLength: [],
      formData: _.cloneDeep(jsonData.formData),
      id: ''
    }
  },
  watch: {
  },
  created () {
    this.init()
  },
  mounted () {
  },
  methods: {
    async init () {
      this.collapseLength = [...new Array(this.formData.length).keys()]
      this.$store.state.app.loading = this.$loading(loading)
      this.id = this.$route.query.id || ''
      const res = await getRestList({page: 1, per_page: 1000})
      const { arr } = utils.formatTableData(res.data.objects)
      this.formData[0].createDateItem[1].options = arr
      if (this.id) {
        const typeRes = await previewRestType(this.id)
        if (res.status === 200 && typeRes.data) {
          const typeObj = JSON.parse(JSON.stringify(typeRes.data.rest_type))
          typeObj.images_attributes = typeObj.images
          delete typeObj['images']
          this.modifyData = {...(typeObj)}
        }
      }
      this.$store.state.app.loading.close()
    },
    handleChange (data, prop, item, cdata) {
      // if (prop === 'couponable_type') {
      //   if (data === 'ShopItem') {
      //     this.btnTxt = this.$t('button.next')
      //   } else {
      //     this.btnTxt = this.$t('button.confirm')
      //   }
      // }
    },
    formSubmit (data) {
      this.$store.state.app.loading = this.$loading(loading)
      const obj = JSON.parse(JSON.stringify(data))
      obj.rank = Number(obj.rank)
      if (obj.images_attributes && obj.images_attributes.length > 0) {
        obj.images_attributes.forEach((d) => {
          if (Object.hasOwn(d, '_destroy')) {
            delete d['_destroy']
          }
        })
      }
      let api = () =>createRestType(obj)
      if (this.id) {
        api = () => updateRestType(this.id, obj)
      }
      api().then((res) => {
        if (res.status === 200) {
          this.$message({
            type: 'success',
            message: this.$t('message.operateSuccess')
          })
          setTimeout(() => {
            this.$router.push({
              name: 'restsManagerCategoryList'
            })
          }, constant.ROUTER_CHANGE_INTERVAL)
        }
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
    },
    handleCancelClick () {
      this.$router.back(-1)
    },
    afterAdd (creatData, alterData, prop) {
    },
    removeArray (createData, obj) {
    },
    checkDisable (createData) {
    },
    handleSwitchChange (value, createDate, prop) {
    }
  }
}
