<template>
  <div>
    <alter-form
      labelWidth="120px"
      :watchImageVisible="true"
      :collapseLength="collapseLength"
      :modifyData="modifyData"
      @formSubmit="formSubmit"
      :formData="formData"
      @handleChange="handleChange"
    />
  </div>
</template>

<script>
import onlineCouponEdit from './index.js'
export default onlineCouponEdit
</script>

<style lang="scss" scope>
</style>
